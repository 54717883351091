import spaceimg from "../../asset/img/nasa.jpg";
import Links from "./Links";

export default function Footer() {
  return (
    <section
      className="relative h-64 text-white flex items-center justify-between overflow-hidden"
      id="Footer"
    >
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${spaceimg})` }}
      ></div>
      <div className="relative z-10 ml-8 w-80 ">
        <p className="xs:text-sm md:text-lg lg:text-2xl">
          Designed & Developed by Ahmad Dzaki 😊
        </p>
      </div>
      <div className="relative z-10 flex items-center justify-center flex-grow">
        {/* <h2>alksdjfhaslkdjfhssdfgsdfgsdfgdsfgsdgsdfgsdfgdfsfg</h2> */}
      </div>
      <div className="relative z-10 mr-8 ">
        <Links />
      </div>
    </section>
  );
}
