import React, { useRef, useState, useEffect } from "react";
import { Meteors } from "../ui/Meteor.tsx";

export default function ExpCard({
  isRowReverse,
  image,
  icons,
  desc,
  title,
  imagepos,
  period,
}) {
  const flexClasses = `mb-8 lg:flex md:flex sm:flex justify-between items-center w-full xs:py-16 lg:py-0 md:py-0 sm:py-0 ${
    isRowReverse ? "flex-row-reverse" : ""
  }`;

  const imageclasses = `w-full h-full object-cover pt-2 pb-2 ${imagepos}`;

  // this is for animation shizzz
  const divRef = useRef(null);
  const dateRef = useRef(null);
  const iconRef = useRef(null);
  const [divInView, setDivInView] = useState(false);
  const [dateInView, setDateInView] = useState(false);
  const [iconInView, setIconInView] = useState(false);

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === divRef.current) {
            setDivInView(true);
            observer.unobserve(divRef.current);
          } else if (entry.target === dateRef.current) {
            setDateInView(true);
            observer.unobserve(dateRef.current);
          } else if (entry.target === iconRef.current) {
            setIconInView(true);
            observer.unobserve(iconRef.current);
          }
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.5,
    });

    if (divRef.current) {
      observer.observe(divRef.current);
    }
    if (dateRef.current) {
      observer.observe(dateRef.current);
    }
    if (iconRef.current) {
      observer.observe(iconRef.current);
    }

    const divRefValue = divRef.current;
    const dateRefValue = dateRef.current;
    const iconRefValue = iconRef.current;

    return () => {
      if (divRefValue) {
        observer.unobserve(divRefValue);
      }
      if (dateRefValue) {
        observer.unobserve(dateRefValue);
      }
      if (iconRefValue) {
        observer.unobserve(iconRefValue);
      }
    };
  }, []);

  return (
    <div className={flexClasses}>
      <div
        ref={dateRef}
        className={`titles order-1 w-5/12 ${
          isRowReverse ? "text-left" : "text-right"
        } ${
          dateInView
            ? isRowReverse
              ? "animate-fade-in-left"
              : "animate-fade-in-right"
            : "opacity-0"
        }`}
      >
        <p className="xs:text-sm md:text-lg lg:text-2xl">{period}</p>
      </div>
      <div
        ref={iconRef}
        className={`z-20 flex items-center order-1 bg-fuchsia-950 shadow-xl rounded-full lg:w-16 lg:h-16 md:w-12 md:h-12 xs:w-10 xs:h-10 xs:invisible sm:visible md:visible lg:visible
      ${iconInView ? "animate-fade-in" : "opacity-0"}`}
      >
        <span className="mx-auto mb-1 text-lg">{icons}</span>
      </div>
      {/* below is the card */}
      <div
        ref={divRef}
        className={`relative order-1 lg:w-5/12 md:w-5/12 sm:w-5/12 xs:w-full px-6 py-4 items-center justify-center overflow-hidden rounded-xl border border-black bg-gradient-to-r from-zinc-900 to-gray-950 shadow-2xl
        ${
          divInView
            ? isRowReverse
              ? "animate-fade-in-right"
              : "animate-fade-in-left"
            : "opacity-0"
        }`}
      >
        <Meteors number={10} />
        <div />
        <div className="relative w-90 h-64">
          <img src={image} alt="Example" className={imageclasses} />
          <h3 className="absolute bottom-0 left-0 bg-black bg-opacity-20 px-2 mb-2 font-semibold xs:text-md md:text-lg lg:text-2xl titles">
            {title}
          </h3>
        </div>
        <p className="text-gray-100 leading-tight xs:text-sm md:text-lg lg:text-2xl py-2 desc">
          {desc}
        </p>
      </div>
    </div>
  );
}
