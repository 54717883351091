import ProjectCard from "./ProjectCard";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const MoreProjects = () => {
  // const [projects, setProjects] = useState([]);

  // useEffect(() => {
  //   fetch("https://apex.oracle.com/pls/apex/dzakitest/projects/projinfo/")
  //     .then((response) => response.json())
  //     // .then((data) => setProjects(data.items)) // to fetch all items instead
  //     .then((data) => {
  //       // Extract the first 3 items
  //       const firstThreeItems = data.items.slice(3);
  //       setProjects(firstThreeItems);
  //     })
  //     .catch((error) => console.error("Error fetching data:", error));
  // }, []);

  return (
    <main
      className="bg-zinc-800 text-white xs:px-4 sm:px-6 xs:py-8 sm:py-12 lg:py-16 min-h-screen w-full"
      id="moreProjects"
    >
      <div className="container mx-auto">
        <Link
          to="/"
          className="inline-block hover:scale-105 font-montserrat-regular xs:text-sm sm:text-base lg:text-md mt-10"
        >
          ← Home
        </Link>

        <h2 className="font-bold mb-5 border-b-[5px] border-cyan-400 pb-2 font-mono md:w-[174px] lg:w-[350px] sm:w-[140px] xs:w-[95px] titles xs:mt-2 sm:mt-4 lg:mt-6 xs:text-3xl sm:text-4xl lg:text-5xl w-fit">
          More Projects!
        </h2>

        <h1 className="xs:text-2xl sm:text-3xl lg:text-4xl">Coming Soon!</h1>
        {/* <div className="container relative flex flex-col justify-between h-full px-10 mx-auto xl:px-0 mt-5">
          <div className="lg:py-24 md:py-24 sm:py-24 xs:pt-24 xs:pb-10"></div>
          <div className="w-full">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {projects.map((project, index) => {
                console.log("rendering item " + index);
                return (
                  <ProjectCard
                    key={project.id} // Ensure each project has a unique key
                    title={project.title}
                    desc={project.description}
                    link={project.link}
                    delay={300} // Pass the delay prop
                  />
                );
              })}
            </div>
          </div>
        </div> */}
      </div>
    </main>
  );
};

export default MoreProjects;
