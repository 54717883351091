import ProjectCard from "./ProjectCard";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ProjectsContext } from "./ProjectsContext";

const Projects = () => {
  // const [projects, setProjects] = useState([]);
  const projects = useContext(ProjectsContext);

  // useEffect(() => {
  //   fetch("https://apex.oracle.com/pls/apex/dzakitest/projects/projinfo/")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const firstThreeItems = data.items.slice(0, 5);
  //       setProjects(firstThreeItems);
  //     })
  //     .catch((error) => console.error("Error fetching data:", error));
  // }, []);

  // const memoizedProjects = useMemo(() => {
  //   return projects.map((project, index) => {
  //     console.log("rendering item " + index);
  //     return (
  //       <ProjectCard
  //         key={project.id} // Ensure each project has a unique key
  //         title={project.title}
  //         desc={project.description}
  //         link={project.link}
  //         delay={300} // Pass the delay prop
  //       />
  //     );
  //   });
  // }, [projects]);

  return (
    <section className="bg-zinc-800 text-white pb-36" id="projects">
      <div className="container relative flex flex-col justify-between h-full px-10 mx-auto xl:px-0 mt-5">
        <div className="lg:py-24 md:py-24 sm:py-24 xs:pt-24 xs:pb-10">
          <h2 className="font-bold mb-5 border-b-[5px] border-cyan-400 pb-2 font-mono lg:text-5xl md:text-3xl sm:text-2xl md:w-[174px] lg:w-[278px] sm:w-[140px] xs:w-[95px] titles">
            My Projects
          </h2>
        </div>
        <div className="w-full">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {projects.map((project, index) => {
              // console.log("Rendering project with index:", index);
              return (
                <ProjectCard
                  key={project.id}
                  title={project.title}
                  desc={project.description}
                  link={project.link}
                  delay={300}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex inset-x-0 justify-center items-center pt-32">
        <Link to="/more-projects">
          <button className="bg-fuchsia-900 text-violet-300 border border-violet-400 border-b-4 font-medium overflow-hidden relative px-4 py-2 rounded-md hover:brightness-150 hover:border-t-4 hover:border-b active:opacity-75 outline-none duration-300 group">
            <span className="bg-fuchsia-900 shadow-violet-400 absolute -top-[150%] left-0 inline-flex w-80 h-[5px] rounded-md opacity-50 group-hover:top-[150%] duration-500 shadow-[0_0_10px_10px_rgba(0,0,0,0.3)]"></span>
            More Projects ➜
          </button>
        </Link>
      </div>
    </section>
  );
};

export default Projects;
