import React, { useEffect, useRef, useState } from "react";
import myimg from "../../asset/img/Ahmad_Dzaki-02.jpg";

export default function Aboutme() {
  const [infoInView, setInfoInView] = useState(false);
  const [imgInView, setImgInView] = useState(false);

  const infoRef = useRef(null);
  const imgRef = useRef(null);

  // useEffect(() => {
  //   const observerCallback = (entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.target === infoRef.current) {
  //         setInfoInView(entry.isIntersecting);
  //       } else if (entry.target === imgRef.current) {
  //         setImgInView(entry.isIntersecting);
  //       }
  //     });
  //   };

  useEffect(() => {
    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === infoRef.current) {
            setInfoInView(true);
            observer.unobserve(infoRef.current); // Stop observing once it's in view
          } else if (entry.target === imgRef.current) {
            setImgInView(true);
            observer.unobserve(imgRef.current); // Stop observing once it's in view
          }
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.1,
    });

    if (infoRef.current) {
      observer.observe(infoRef.current);
    }
    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    const infoRefValue = infoRef.current;
    const imgRefValue = imgRef.current;

    return () => {
      if (infoRefValue) {
        observer.unobserve(infoRefValue);
      }
      if (imgRefValue) {
        observer.unobserve(imgRefValue);
      }
    };
  }, []);

  return (
    <section className="text-white px-5 py-32 bg-zinc-800" id="aboutme">
      <div className="container mx-auto ">
        <h2 className=" font-bold mb-5 border-b-[5px] border-cyan-400 pb-2 font-mono lg:text-5xl md:text-3xl sm:text-2xl md:w-[250px] lg:w-[380px] sm:w-[200px] xs:w-[130px]">
          Hey, I'm <span id="myname">Dzaki!</span>
        </h2>
        <div className="grid md:grid-cols-2 lg:mt-20 sm:mt-7 xs:mt-7 items-center justify-center md:justify-between">
          <div
            ref={imgRef}
            className={`about-img lg:mr-64 md:mr-16 sm:mr-6 sm:mb-10 xs:mr-0 xs:mb-10 ${
              imgInView ? "animate-fade-in-left" : "opacity-0"
            }`}
          >
            <img
              src={myimg}
              alt="myself"
              className="ml-auto rounded-full xs:w-40 xs:h-40 sm:w-48 sm:h-48 md:w-56 md:h-56 lg:w-80 lg:h-80 xs:mx-auto xs:mt-6"
            />
            {/* md:ml-auto rounded-full" /> */}
          </div>

          <div
            ref={infoRef}
            className={`about-info ${
              infoInView ? "animate-fade-in-right" : "opacity-0"
            }`}
          >
            <div className="flex flex-col gap-4 flex-2 xs:pr-6 sm:pr-10 lg:px-0 ">
              <p className="font-karla mydesc xs:text-sm md:text-lg lg:text-2xl">
                Hi, My Name Is Ahmad Dzaki but everyone just calls me Dzaki
                (silent D). I am an aspiring software engineer, recently
                graduated from Republic Polytechnic, Diploma in Information
                Technology.
              </p>
              <p className="font-karla mydesc xs:text-sm md:text-lg lg:text-2xl">
                I'm planning to explore and expand my skillsets like building
                this website using React and Tailwind CSS. I'm always looking
                for opportunities to work with those who are willing to share
                their knowledge as much as I want to learn.
              </p>
              <p className="font-karla mydesc xs:text-sm md:text-lg lg:text-2xl">
                Ultimately, I aim to be able to create something beautiful and
                user-oriented for people that enhances their daily activities be
                it for work or personal use.
              </p>
              <p className="font-karla mydesc xs:text-sm md:text-lg lg:text-2xl">
                In my spare time I go to the gym and play games! Recently been
                obsessed with Hades II by Supergiant Games.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
