import SPFimg from "../../asset/img/SPF.jpeg";
import RPimg from "../../asset/img/RP.jpeg";
import Oimg from "../../asset/img/Oracle.jpeg";

import {
  IconBackpack,
  IconDeviceLaptop,
  IconBuilding,
} from "@tabler/icons-react";

import ExpCard from "./ExpCard.js";

let currentId = 0;

function generateIncrementingUUID() {
  currentId += 1;
  return `uuid-${currentId}`;
}

const expContent = [
  {
    id: generateIncrementingUUID(),
    rev: false,
    img: RPimg,
    icon: <IconBackpack className="lg:w-10 lg:h-10 xs:w-6 xs:h-6" />,
    des: "Graduated from the diploma in Information Technology with Merit. Valedictorian of graduating class of 2024. Competed in Polyfintech Hackathon 100 in 2022 and NetAcad Riders in 2023.",
    title: "Republic Polytechnic",
    imgpos: "object-top",
    period: "Apr 2021 - Apr 2024",
  },
  {
    id: generateIncrementingUUID(),
    rev: true,
    img: Oimg,
    icon: <IconDeviceLaptop className="lg:w-10 lg:h-10 xs:w-6 xs:h-6" />,
    des: "As a Back-end Developer I work mainly in Oracle APEX where I design databases, manage data flow and manipulation, handle data output through APIs and applicaition development.",
    title: "Internship (Oracle)",
    imgpos: "",
    period: "Apr 2023 - Apr 2024",
  },
  {
    id: generateIncrementingUUID(),
    rev: false,
    img: SPFimg,
    icon: <IconBuilding className="lg:w-10 lg:h-10 xs:w-6 xs:h-6" />,
    des: "Served in the National Police Force. Part of the police contigent for National Day Parade 2019. Was a PNSB HR admin managing payrolls.",
    title: "National Service (SPF)",
    imgpos: "",
    period: "Feb 2019 - Feb 2021",
  },
];
// text-5xl font-bold mb-5 border-b-[5px] w-[264px] border-cyan-400 pb-2 titles
export default function Experience() {
  return (
    <section className="bg-zinc-800 text-white" id="experience">
      <div className="container mx-auto px-0 py-8">
        <div className="py-4" id="Experience-Title">
          <h2 className="font-bold mb-5 border-b-[5px] border-cyan-400 pb-2 font-mono lg:text-5xl md:text-3xl sm:text-2xl md:w-[167px] lg:w-[264px] sm:w-[135px] xs:w-[90px] titles">
            Experience
          </h2>
        </div>
        <div className="relative wrap overflow-hidden">
          <div className="border-2-2 absolute border-opacity-20 border-cyan-50 h-full border-2 left-1/2"></div>

          {expContent.map((exp) => (
            <ExpCard
              key={exp.id}
              isRowReverse={exp.rev}
              image={exp.img}
              icons={exp.icon}
              desc={exp.des}
              title={exp.title}
              imagepos={exp.imgpos}
              period={exp.period}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
