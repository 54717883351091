// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&family=Permanent+Marker&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&family=NTR&family=Permanent+Marker&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  font-size: x-large;
}
svg {
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./src/asset/css/Fonts.css"],"names":[],"mappings":"AACA;EACE,kBAAkB;AACpB;AAIA;EACE,qBAAqB;AACvB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap\");\np {\n  font-size: x-large;\n}\n@import url(\"https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&family=Permanent+Marker&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&family=NTR&family=Permanent+Marker&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap\");\nsvg {\n  display: inline-block;\n}\n@import url(\"https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap\");\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
