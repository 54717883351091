import React, { useState, useEffect } from "react";
import { IconArrowRight, IconLock } from "@tabler/icons-react";
import { TypeAnimation } from "react-type-animation";

import Starfield from "../ui/Starfield.tsx";
import Arrow from "./Arrow.js";
import Loader from "./Loader.js";

export default function Header() {
  const [loading, setLoading] = useState(true);
  const [animate, setAnimate] = useState(false);
  const [nameAnimate, setNameAnimate] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      // this is to trigger after 2 seconds
      setAnimate(true); // start animation (zoomout)
      setNameAnimate(true);
      const animationTimer = setTimeout(() => {
        setLoading(false); //hide loader

        setShowHeader(true); //show header
        setShowFooter(true); //show footer
      }, 600); // Duration of the animation

      return () => clearTimeout(animationTimer);
    }, 2000); // Change the duration to your preference

    return () => clearTimeout(timer); //any timers are cleared when the component unmounts to prevent memory leaks.
  }, []);

  return (
    <div className="relative h-full w-full bg-neutral-900" id="Header">
      <Starfield
        starCount={8000}
        starColor={[255, 250, 250]}
        speedFactor={0.15}
        backgroundColor="black"
      />
      {showHeader && ( // the && is used to determine if showHeader is true/false, if true, everything after the && is rendered and vice versa
        <header className="absolute inset-x-0 top-0 z-50">
          <nav
            className="flex items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1"></div>
            <div className="lg:flex lg:flex-1 lg:justify-end" id="icons">
              <a
                href="https://apex.oracle.com/pls/apex/r/dzakitest/react-project/home?session=14109789388576"
                target="blank"
                className="text-sm font-semibold leading-6 text-gray-300"
              >
                <IconLock />
                <IconArrowRight />
              </a>
            </div>
          </nav>
        </header>
      )}

      <div className="relative isolate px-6 lg:px-8">
        <div className="flex h-screen">
          <div className="m-auto">
            <div className="relative flex h-screen justify-center items-center">
              <div className="text-center">
                {loading ? (
                  <div className={animate ? "animate-zoom-out" : ""}>
                    <Loader />
                  </div>
                ) : (
                  <>
                    <div
                      className={
                        nameAnimate
                          ? "animate-slidein opacity-0 [--slidein-delay:300ms]"
                          : ""
                      }
                    >
                      <h1
                        id="name"
                        className="lg:text-6xl font-bold text-gray-100 sm:text-3xl md:text-4xl pb-2"
                      >
                        Ahmad Dzaki
                      </h1>
                    </div>
                    <div
                      className={`md:mt-5 lg:mt-7 sm:mt-3
                        ${
                          nameAnimate
                            ? "animate-slidein opacity-0 [--slidein-delay:500ms]"
                            : ""
                        }
                      `}
                    >
                      <TypeAnimation
                        sequence={[
                          "An aspiring Software Engineer",
                          1500,
                          "A Food Enthusiast",
                          1500,
                          "A Gym Lover",
                          1500,
                        ]}
                        wrapper="span"
                        className=" sm:text-3xl md:text-4xl lg:text-6xl font-normal inline-block tracking-tight text-gray-100"
                        speed={7}
                        style={{
                          fontFamily: "Roboto Mono, monospace",
                        }}
                        repeat={Infinity}
                      />
                      <hr className="w-13 h-1 mx-auto mt-2 mb-4 bg-gray-100 border-0 rounded md:my-6 dark:bg-gray-100 pt"></hr>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showFooter && (
        <footer className="flex absolute inset-x-0 bottom-0 z-50 justify-center items-center">
          <div className="p-5 lg:px-6">
            <Arrow />
          </div>
        </footer>
      )}
    </div>
  );
}
