// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./asset/css/Fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#myname {
  font-family: "Permanent Marker", cursive;
  font-weight: 400;
  font-style: normal;
}

.titles {
  font-family: "Alexandria", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.desc,
.tooltip {
  font-family: "NTR", sans-serif;
  font-weight: 400;
  font-style: normal;
}

#name {
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,wCAAwC;EACxC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,qCAAqC;EACrC,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;;EAEE,8BAA8B;EAC9B,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,qCAAqC;EACrC,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["@import url(\"./asset/css/Fonts.css\");\n\n#myname {\n  font-family: \"Permanent Marker\", cursive;\n  font-weight: 400;\n  font-style: normal;\n}\n\n.titles {\n  font-family: \"Alexandria\", sans-serif;\n  font-optical-sizing: auto;\n  font-weight: 400;\n  font-style: normal;\n}\n\n.desc,\n.tooltip {\n  font-family: \"NTR\", sans-serif;\n  font-weight: 400;\n  font-style: normal;\n}\n\n#name {\n  font-family: \"Roboto Mono\", monospace;\n  font-optical-sizing: auto;\n  font-weight: 400;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
