import React, { useRef, useState, useEffect, memo } from "react";

const ProjectCard = memo(({ title, desc, bgclr, borderclr, link, delay }) => {
  const divRef = useRef(null);
  const [divInView, setDivInView] = useState(false);

  const handleClick = () => {
    window.open(link, "_blank");
  };

  const spanclasses = `absolute top-0 left-0 w-full h-full mt-1 ml-1 ${
    bgclr ? bgclr : "bg-fuchsia-900"
  } rounded-lg transition-transform transform group-hover:translate-x-2 group-hover:translate-y-2 group-active:translate-x-1 group-active:translate-y-1`;

  const borderclasses = `relative h-full p-5 bg-neutral-900 border-2 ${
    borderclr ? borderclr : "border-fuchsia-900"
  } rounded-lg transition-transform transform group-hover:translate-x-[-2px] group-hover:translate-y-[-2px] group-active:translate-x-[-1px] group-active:translate-y-[-1px]`;

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === divRef.current) {
            setDivInView(true);
            observer.unobserve(divRef.current);
          }
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.2,
    });

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    const divRefValue = divRef.current;

    return () => {
      if (divRefValue) {
        observer.unobserve(divRefValue);
      }
    };
  }, []);

  return (
    <div
      ref={divRef}
      className={`w-full sm:w-auto h-auto ${
        divInView
          ? `animate-slidein opacity-0 [--slidein-delay:${delay}ms]`
          : "opacity-0"
      }`}
      onClick={handleClick}
    >
      <div className="relative h-full ml-0 mr-0 sm:mr-10 group">
        <span className={spanclasses}></span>
        <div className={borderclasses}>
          <div className="flex items-center -mt-1">
            <h3 className="mt-2 ml-3 xs:text-sm md:text-lg lg:text-2xl font-bold text-gray-200 titles">
              {title}
            </h3>
          </div>
          <p className="mt-3 mb-1 text-xs font-medium text-cyan-400 uppercase">
            ------------
          </p>
          <p className="mb-2 text-gray-200 desc xs:text-sm md:text-lg lg:text-2xl">
            {desc}
          </p>
        </div>
      </div>
    </div>
  );
});

export default ProjectCard;
