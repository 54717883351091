import React, { createContext, useState, useEffect } from "react";

export const ProjectsContext = createContext();

export const ProjectsProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetch("https://apex.oracle.com/pls/apex/dzakitest/projects/projinfo/")
      .then((response) => response.json())
      .then((data) => {
        const firstThreeItems = data.items.slice(0, 3);
        setProjects(firstThreeItems);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <ProjectsContext.Provider value={projects}>
      {children}
    </ProjectsContext.Provider>
  );
};
