import React, { useEffect, useState } from "react";
import "../../asset/css/Sidenav.css";

const sections = [
  { id: "Header", label: "INTRO", ariaLabel: "Intro" },
  { id: "aboutme", label: "ABOUT ME", ariaLabel: "About Me" },
  { id: "experience", label: "EXPERIENCE", ariaLabel: "Experience" },
  { id: "projects", label: "PROJECTS", ariaLabel: "Projects" },
];

export default function Sidenav() {
  const [activeSection, setActiveSection] = useState("");

  const scrollToSection = (sectionId) => {
    return (e) => {
      e.preventDefault();
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({
          behavior: "smooth",
        });
      }
    };
  };

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.5, // Adjust this value as needed
    });

    sections.forEach((section) => {
      const sectionElement = document.getElementById(section.id);
      if (sectionElement) {
        observer.observe(sectionElement);
      }
    });

    return () => {
      sections.forEach((section) => {
        const sectionElement = document.getElementById(section.id);
        if (sectionElement) {
          observer.unobserve(sectionElement);
        }
      });
    };
  }, []);

  return (
    <ul className="sidenav-2">
      {sections.map((section) => (
        <li key={section.id} className="icon-content">
          <button
            onClick={scrollToSection(section.id)}
            aria-label={section.ariaLabel}
            data-social={section.ariaLabel.toLowerCase()}
            className={activeSection === section.id ? "filled" : ""}
          >
            <div className="filled"></div>
          </button>
          <div className="tooltip">{section.label}</div>
        </li>
      ))}
    </ul>
  );
}
