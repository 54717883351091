import React, { useEffect, useRef } from "react";
import "./App.css";
import Header from "./components/Header/Header.js";
import Aboutme from "./components/Content/Aboutme";
import Experience from "./components/Content/Experience";
import Projects from "./components/Content/Projects";
import Footer from "./components/Footer/Footer";
import Sidenav from "./components/ui/Sidenav";
import MoreProjects from "./components/Content/MoreProjects.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ProjectsProvider } from "./components/Content/ProjectsContext.js";

function App() {
  const sidenavRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const aboutMeSection = document.getElementById("Experience-Title");
      if (aboutMeSection) {
        const aboutMePosition = aboutMeSection.getBoundingClientRect().top;
        if (sidenavRef.current) {
          if (aboutMePosition <= window.innerHeight) {
            sidenavRef.current.style.display = "block";
          } else {
            sidenavRef.current.style.display = "none";
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check visibility on initial load

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to check if the current device is a mobile device
  const isMobileDevice = () => {
    return window.innerWidth <= 1000; // Adjust this value based on your design's breakpoint
  };

  return (
    <ProjectsProvider>
      <Router>
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Aboutme />
                <Experience />
                <Projects />
                <Footer />
                {!isMobileDevice() && (
                  <div
                    ref={sidenavRef}
                    style={{ display: "none" }}
                    className="animate-fade-in"
                  >
                    <Sidenav />
                  </div>
                )}
              </>
            }
          />
          <Route path="/more-projects" element={<MoreProjects />} />
        </Routes>
      </Router>
    </ProjectsProvider>
  );
}

export default App;
